import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router);
import dates  from './dates';
// State Managements & Auth
import store from './store';
const user = store.state.user;


// Containers Full View Main Root
const Main = () => import('./container/AppMain.vue')

// Views - Pages
const Page404 = () => import('./container/Page404.vue')

// Views - Auth
const Register = () => import('@/components/Register.vue')
const Login = () => import('@/components/Login.vue')
const ForgotPassword = () => import('@/components/ForgotPassword.vue')
const UserProfile = () => import('@/components/UserProfile.vue')
const Exams = () => import('@/components/Exams.vue')
const ExamDetail = () => import('@/components/ExamDetail.vue')
const UserExams = () => import('@/components/UserExams.vue')
const AboutUs = () => import('@/components/AboutUs.vue')


const router = new Router({
    mode: 'hash', // Demo is living in GitHub.io, so required!
    // mode: 'history',
    linkActiveClass: 'open active',
    base: __dirname,
    scrollBehavior: () => ({ y: 0 }),
    routes: [
        
        

        /* Home */
        {
            path: '/',
            name: 'Home',
            component: Exams,
            meta: {
                breadcrumb: 'Home',
                module: 'Home'
            },
        },

         /* Login */
         {
            path: '/login',
            name: 'Login',
            component: Login,
            meta: {
                breadcrumb: 'Login',
                module: 'Login'
            },
        },

        /* Register */
        {
            path: '/register',
            name: 'Register',
            component: Register,
            meta: {
                breadcrumb: 'Register',
                module: 'Register'
            },
        },

        /* ForgotPassword */
        {
            path: '/forgot-password',
            name: 'ForgotPassword',
            component: ForgotPassword,
            meta: {
                breadcrumb: 'ForgotPassword',
                module: 'ForgotPassword'
            },
        },

        /* User Profile */
        {
            path: '/profile',
            name: 'UserProfile',
            component: UserProfile,
            meta: {
                breadcrumb: 'UserProfile',
                module: 'UserProfile'
            },
        },

        /* User Exams */
        {
            path: '/user-exams',
            name: 'UserExams',
            component: UserExams,
            meta: {
                breadcrumb: 'UserExams',
                module: 'UserExams'
            },
        },

        /* Exam */
        {
            path: '/exams',
            name: 'Exams',
            component: Exams,
            meta: {
                breadcrumb: 'Exams',
                module: 'Exams'
            },
        },

        /* ExamDetail */
        {
            path: '/exam/:slug',
            name: 'ExamDetail',
            component: ExamDetail,
            meta: {
                breadcrumb: 'ExamDetail',
                module: 'ExamDetail'
            },
        },

        /* AboutUs */
        {
            path: '/about-us',
            name: 'AboutUs',
            component: AboutUs,
            meta: {
                breadcrumb: 'AboutUs',
                module: 'AboutUs'
            },
        },

        /* 400, 404, 403, 500, 201 etc */        
        { 
            path: '*',
            name: 'Page404',
            component: Page404,
            meta: {
                breadcrumb: 'Page404',
                module: 'Page404'
            },
        },

    ]
});

export default router;
