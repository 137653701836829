import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';
import './plugins/bootstrap-vue';
import BootstrapVue from 'bootstrap-vue';
import VueRouter from 'vue-router';
import router from './router';
import './registerServiceWorker';
import App from './App.vue';
window.Vue = Vue;
window.vue = Vue;
window._vue = Vue;
Vue.use(BootstrapVue);
Vue.use(VueRouter);
Vue.use(router);

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

/* Vue State management */
/* Vue Sync need to sync between state and router */
import { sync } from 'vuex-router-sync';
sync(store, router);
import Vuex from 'vuex'
Vue.use(Vuex);
import store from './store';
import dates from './dates';
Vue.use(store);
Vue.use(dates);

/* Vue Validate */
import VeeValidate from 'vee-validate';
const config = {
  events: 'input|blur'
};
Vue.use(VeeValidate, config);
import CxltToastr from 'cxlt-vue2-toastr';
import './assets/toaster.css'
var toastrConfigs = {
  position: 'top right',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
  showDuration: 1500,
  hideDuration: 1500,
  delay: 0,
  timeOut: '1500',
  progressBar: true,
}
Vue.use(CxltToastr, toastrConfigs);
import swal from 'sweetalert'
// Vue.use(swal);

import {globals} from './globals'

/* Vue Configuration */
Vue.config.productionTip = true;
Vue.config.devtools = true;
Vue.config.debug = true;
Vue.config.silent = true;
Vue.config.performance = true;
import Multiselect from 'vue-multiselect';
Vue.config.productionTip = false
Vue.mixin({    
  data() {
    return {
        // local url
        // mainUrl: 'http://localhost:3001/Development/',
        // imageUrl: 'http://localhost:3001/',
        // chatUrl: 'http://localhost:3020/Development/',
        // chatApiUrl: 'http://localhost:3020/Chat/Development/',
        // chatImgUrl: 'http://localhost:3020/Chat/',

        // live url
        mainUrl: 'http://64.227.136.68:3001/Development/',
        imageUrl: 'http://64.227.136.68:3001/',
        chatUrl: 'http://64.227.136.68:3001/Development/',
        chatApiUrl: 'http://64.227.136.68:3001/Chat/Development/',
        chatImgUrl: 'http://64.227.136.68:3001/Chat/',
        
    }
  },
});

new Vue({
  render: h => h(App),
  router,
  store,
  swal,
  globals,
  $,
  Vue,
  Multiselect,
  components: {
    App,
    Vue,
    router,
    store,
    dates,
    globals,
    $,
    swal,
    Multiselect
  },
  created(){
    window.Vue = this;
    window.vue = this;
    window._vue = this;
  }
}).$mount('.main-root-app')
