export const globals = {
    // local url
    // mainUrl: 'http://localhost:3001/Development/',
    // imageUrl: 'http://localhost:3001/',
    // chatUrl: 'http://localhost:3020/Development/',
    // chatApiUrl: 'http://localhost:3020/Chat/Development/',
    // chatImgUrl: 'http://localhost:3020/Chat/',

    // live url
    mainUrl: 'http://64.227.136.68:3001/Development/',
    imageUrl: 'http://64.227.136.68:3001/',
    chatUrl: 'http://64.227.136.68:3001/Development/',
    chatApiUrl: 'http://64.227.136.68:3001/Chat/Development/',
    chatImgUrl: 'http://64.227.136.68:3001/Chat/',

    chatRegister: false,
};

