function addZero(i) {
    if (i < 10) {
        i = "0" + i;
    }
    return i;
}
var dateNow = new Date();
var dd = addZero(dateNow.getDate());
var monthSingleDigit = dateNow.getMonth() + 1;
var mm = addZero(monthSingleDigit);
var yy = dateNow.getFullYear().toString();
var hh = addZero(dateNow.getHours().toString());
var ii = addZero(dateNow.getMinutes().toString());
var ss = addZero(dateNow.getSeconds().toString());
var timestamp = yy +'-'+ mm +'-'+ dd +' '+ hh +':'+ ii +':'+ ss;
var ampm = hh > 12 ? 'pm' : 'am';
var twentyHourTime = hh;
if(hh > 12){
    twentyHourTime = addZero((parseInt(hh) - 12));
}
timestamp = yy +'-'+ mm +'-'+ dd +' '+ twentyHourTime +':'+ ii +':'+ ss + ', ' +ampm;
console.log('application starting time ==> '+ timestamp);

function _generateTimes(date, units, interval) {
    var ret = new Date(date); //don't change original date
    /*console.log('newDate');
    console.log(ret);*/
    var newDate = new Date(date);
    var checkRollover = function() { if(ret.getDate() != newDate.getDate())ret.setDate(0);};
    switch(units.toLowerCase()) {
        case 'year'   :  ret.setFullYear(ret.getFullYear() + parseInt(interval)); checkRollover();  break;
        case 'quarter':  ret.setMonth(ret.getMonth() + (3*parseInt(interval))); checkRollover();  break;
        case 'month'  :  ret.setMonth(ret.getMonth() + parseInt(interval)); checkRollover();  break;
        case 'week'   :  ret.setDate(ret.getDate() + (7*parseInt(interval)));  break;
        case 'day'    :  ret.setDate(ret.getDate() + parseInt(interval));  break;
        case 'hour'   :  ret.setTime(ret.getTime() + (parseInt(interval)*3600000));  break;
        case 'minute' :  ret.setTime(ret.getTime() + (parseInt(interval)*60000));  break;
        case 'second' :  ret.setTime(ret.getTime() + (parseInt(interval)*1000));  break;
        default       :  ret = undefined;  break;
    }

    function addZero(i) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }
    var dd = addZero(ret.getDate().toString());
    var monthSingleDigit = ret.getMonth() + 1;
    var mm = addZero(monthSingleDigit);
    var yy = ret.getFullYear().toString();
    var hh = addZero(ret.getHours().toString());
    var ii = addZero(ret.getMinutes().toString());
    var ss = addZero(ret.getSeconds().toString());
    var timestamp = yy +'-'+ mm +'-'+ dd +' '+ hh +':'+ ii +':'+ ss;
    /*console.log('new date time ===>   ' + timestamp);*/
    return timestamp;
}

function _generateDate(date, units, interval, format) {
    var ret = new Date(date); //don't change original date
    /*console.log('newDate');
    console.log(ret);*/
    var newDate = new Date(date);
    var checkRollover = function() { if(ret.getDate() != newDate.getDate())ret.setDate(0);};
    switch(units.toLowerCase()) {
        case 'year'   :  ret.setFullYear(ret.getFullYear() + parseInt(interval)); checkRollover();  break;
        case 'quarter':  ret.setMonth(ret.getMonth() + (3*parseInt(interval))); checkRollover();  break;
        case 'month'  :  ret.setMonth(ret.getMonth() + parseInt(interval)); checkRollover();  break;
        case 'week'   :  ret.setDate(ret.getDate() + (7*parseInt(interval)));  break;
        case 'day'    :  ret.setDate(ret.getDate() + parseInt(interval));  break;
        case 'hour'   :  ret.setTime(ret.getTime() + (parseInt(interval)*3600000));  break;
        case 'minute' :  ret.setTime(ret.getTime() + (parseInt(interval)*60000));  break;
        case 'second' :  ret.setTime(ret.getTime() + (parseInt(interval)*1000));  break;
        default       :  ret = undefined;  break;
    }

    function addZero(i) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }
    var dd = addZero(ret.getDate().toString());
    var monthSingleDigit = ret.getMonth() + 1;
    var mm = addZero(monthSingleDigit);
    var yy = ret.getFullYear().toString();
    var hh = addZero(ret.getHours().toString());
    var ii = addZero(ret.getMinutes().toString());
    var ss = addZero(ret.getSeconds().toString());
   var ms = addZero(ret.getMilliseconds().toString());
    var ampm = hh > 12 ? 'pm' : 'am';
    var twentyHourTime = hh;
    if(hh > 12){
        twentyHourTime = addZero((parseInt(hh) - 12));
    }
    var timestamp = yy +'-'+ mm +'-'+ dd +' '+ hh +':'+ ii +':'+ ss;
    if(format.length >= 4){
        switch(format.toLowerCase()) {
            case 'hh:ii ap, dd-mm-yyyy'  : timestamp =  twentyHourTime +':'+ ii + ' ' + ampm + ', ' + dd +'-'+ mm +'-'+ yy ; break;
            case 'hh:ii ap dd-mm-yyyy'  : timestamp =  twentyHourTime +':'+ ii + ' ' + ampm + ' ' + dd +'-'+ mm +'-'+ yy ; break;
            case 'hh:ii:ss ap dd-mm-yyyy'  : timestamp =  twentyHourTime +':'+ ii +':'+ ss + ' ' + ampm + ' ' + dd +'-'+ mm +'-'+ yy ; break;
            case 'dd-mm-yyyy hh:ii:ss,ap'  : timestamp =  dd +'-'+ mm +'-'+ yy +' '+ twentyHourTime +':'+ ii +':'+ ss + ', ' + ampm; break;
            case 'hh:ii:ss,ap dd-mm-yyyy'  : timestamp =  twentyHourTime +':'+ ii +':'+ ss +', '+ ampm +' '+ dd +'-'+ mm +'-'+ yy; break;
            case 'dd-mm-yyyy hh:ii:ss:ms'  : timestamp = dd +'-'+ mm +'-'+ yy +' '+ hh +':'+ ii +':'+ ss +':'+ ms; break;
            case 'dd-mm-yyyy hh:ii:ss'  : timestamp = dd +'-'+ mm +'-'+ yy +' '+ hh +':'+ ii +':'+ ss; break;
            case 'yyyy-mm-dd hh:ii:ss'  : timestamp = yy +'-'+ mm +'-'+ dd +' '+ hh +':'+ ii +':'+ ss; break;
            case 'yyyy_mm_dd_hh_ii_ss'  : timestamp = yy +'_'+ mm +'_'+ dd +'_'+ hh +'_'+ ii +'_'+ ss; break;
            case 'dd-mm-yyyy'           : timestamp = dd +'-'+ mm +'-'+ yy; break;
            case 'mm/dd/yyyy'           : timestamp = mm +'/'+ dd +'/'+ yy; break;
            case 'dd:mm:yyyy'           : timestamp = dd +':'+ mm +':'+ yy; break;
            case 'dd_mm_yyyy'           : timestamp = dd +'_'+ mm +'_'+ yy; break;
            case 'yyyy_mm_dd'           : timestamp = yy +'_'+ mm +'_'+ dd; break;
            case 'yyyy-mm-dd'           : timestamp = yy +'-'+ mm +'-'+ dd; break;
            case 'yyyy:mm:dd'           : timestamp = yy +'-'+ mm +'-'+ dd; break;
            case 'yyyy'                 : timestamp = yy; break;
            default                     : break;
        }
    }
    return timestamp;
}

function _generateTimeStamp(date) {
    var ret = (date) ? new Date(date) : new Date();
    function addZero(i) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }
    var dd = addZero(ret.getDate());
    var monthSingleDigit = ret.getMonth() + 1;
    var mm = addZero(monthSingleDigit);
    var yy = ret.getFullYear().toString();
    var hh = addZero(ret.getHours().toString());
    var ii = addZero(ret.getMinutes().toString());
    var ss = addZero(ret.getSeconds().toString());
    var timestamp = yy +'-'+ mm +'-'+ dd +' '+ hh +':'+ ii +':'+ ss;
    return timestamp;
}

function _generateDateFormat(date, format) {
    var ret = (date) ? new Date(date) : new Date();
    let fullMonths = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'Octomber',
        'November',
        'December',
    ]
    let shortMonths = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];
    let fullDays = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];
    let shortDays = [
        'Sun',
        'Mon',
        'Tue',
        'Wed',
        'Thu',
        'Fri',
        'Sat',
    ];
    var dd = addZero(ret.getDate());
    var d = ret.getDay();
    var monthSingleDigit = ret.getMonth() + 1;
    var mm = addZero(monthSingleDigit);
    var yy = ret.getFullYear().toString();
    var shortYear = yy.substring(2, 4);
    var hh = addZero(ret.getHours().toString());
    var ii = addZero(ret.getMinutes().toString());
    var ss = addZero(ret.getSeconds().toString());
    var ms = addZero(ret.getMilliseconds().toString());
    var ampm = hh > 12 ? 'PM' : 'AM';
    var AMPM = hh > 12 ? 'pm' : 'am';
    var twentyHourTime = hh;
    if(hh > 12){
        twentyHourTime = addZero((parseInt(hh) - 12));
    }
    var timestamp = yy +'-'+ mm +'-'+ dd +' '+ hh +':'+ ii +':'+ ss;
    if(format.length >= 4){
        switch(format) {
            case 'hh:ii ap, dd-mm-yyyy'  : timestamp =  twentyHourTime +':'+ ii + ' ' + ampm + ', ' + dd +'-'+ mm +'-'+ yy ; break;
            case 'hh:ii ap dd-mm-yyyy'  : timestamp =  twentyHourTime +':'+ ii + ' ' + ampm + ' ' + dd +'-'+ mm +'-'+ yy ; break;
            case 'hh:ii:ss ap dd-mm-yyyy'  : timestamp =  twentyHourTime +':'+ ii +':'+ ss + ' ' + ampm + ' ' + dd +'-'+ mm +'-'+ yy ; break;
            case 'dd-mm-yyyy hh:ii:ss,ap'  : timestamp =  dd +'-'+ mm +'-'+ yy +' '+ twentyHourTime +':'+ ii +':'+ ss + ', ' + ampm; break;
            case 'hh:ii:ss,ap dd-mm-yyyy'  : timestamp =  twentyHourTime +':'+ ii +':'+ ss +', '+ ampm +' '+ dd +'-'+ mm +'-'+ yy; break;
            case 'dd-mm-yyyy hh:ii:ss:ms'  : timestamp = dd +'-'+ mm +'-'+ yy +' '+ hh +':'+ ii +':'+ ss +':'+ ms; break;
            case 'dd-mm-yyyy hh:ii:ss'  : timestamp = dd +'-'+ mm +'-'+ yy +' '+ hh +':'+ ii +':'+ ss; break;
            case 'yyyy-mm-dd hh:ii:ss'  : timestamp = yy +'-'+ mm +'-'+ dd +' '+ hh +':'+ ii +':'+ ss; break;
            case 'yyyy_mm_dd_hh_ii_ss'  : timestamp = yy +'_'+ mm +'_'+ dd +'_'+ hh +'_'+ ii +'_'+ ss; break;
            case 'mm-dd-yyyy'           : timestamp = mm +'-'+ dd +'-'+ yy; break;
            case 'mm/dd/yyyy'           : timestamp = mm +'/'+ dd +'/'+ yy; break;
            case 'dd-mm-yyyy'           : timestamp = dd +'-'+ mm +'-'+ yy; break;
            case 'dd:mm:yyyy'           : timestamp = dd +':'+ mm +':'+ yy; break;
            case 'dd_mm_yyyy'           : timestamp = dd +'_'+ mm +'_'+ yy; break;
            case 'yyyy_mm_dd'           : timestamp = yy +'_'+ mm +'_'+ dd; break;
            case 'yyyy-mm-dd'           : timestamp = yy +'-'+ mm +'-'+ dd; break;
            case 'yyyy:mm:dd'           : timestamp = yy +'-'+ mm +'-'+ dd; break;
            case 'yyyy'                 : timestamp = yy; break;
            case 'hh:ii:ss,ap'          : timestamp = twentyHourTime + ':' + ii + ':' + ss + ' ' + ampm; break;
            case 'hh:ii:ss,AP'          : timestamp =  twentyHourTime +':'+ ii +':'+ ss + ' ' + AMPM; break;
            case 'DDDD, MMMM, YYYY'           : timestamp = fullDays[parseInt(d)] +', '+ fullMonths[parseInt(mm)-1] +' '+ yy; break;
            case 'DDDD, MM, YYYY'           : timestamp = fullDays[parseInt(d)] +', '+ shortMonths[parseInt(mm)-1] +' '+ yy; break;
            case 'DDDD, MM, YY'           : timestamp = fullDays[parseInt(d)] +', '+ shortMonths[parseInt(mm)-1] +' '+ shortYear; break;
            case 'DDDD, MMMM, YY'           : timestamp = fullDays[parseInt(d)] +', '+ fullMonths[parseInt(mm)-1] +' '+ shortYear; break;
            case 'DD, MMMM, YYYY'           : timestamp = shortDays[parseInt(d)] +', '+ fullMonths[parseInt(mm)-1] +' '+ yy; break;
            case 'DD, MMMM, YY'           : timestamp = shortDays[parseInt(d)] +', '+ fullMonths[parseInt(mm)-1] +' '+ shortYear; break;
            case 'DD, MM, YY'           : timestamp = shortDays[parseInt(d)] +', '+ shortMonths[parseInt(mm)-1] +' '+ shortYear; break;
            case 'dd, MMMM, YYYY'           : timestamp = dd +', '+ fullMonths[parseInt(mm)-1] +' '+ yy; break;
            case 'dd, MM, YYYY'           : timestamp = dd +', '+ shortMonths[parseInt(mm)-1] +' '+ yy; break;
            case 'dd, MM, YY'           : timestamp = dd +', '+ shortMonths[parseInt(mm)-1] +' '+ shortYear; break;
            case 'dd, MMMM, YY'           : timestamp = dd +', '+ fullMonths[parseInt(mm)-1] +' '+ shortYear; break;
            case 'M d, Y'           : timestamp = fullMonths[parseInt(mm)-1] +' '+ dd + ', '+ yy; break;
            case 'DDDD, MM D, YYYY, hh:ii, AP'           : timestamp = fullDays[parseInt(d)] +', '+ shortMonths[parseInt(mm)-1] +' '+ dd +', '+ yy +', '+ twentyHourTime +':'+ ii + ' ' + ampm; break;
            default                     : break;
        }
    }
    return timestamp;
}

function generate_token(length){
    var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
    var b = [];  
    for (var i = 0; i<length; i++) {
        var j = (Math.random() * (a.length-1)).toFixed(0);
        b[i] = a[j];
    }
    return b.join("");
}

function generate_img_name(length){
    var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
    var b = [];  
    for (var i = 0; i<length; i++) {
        var j = (Math.random() * (a.length-1)).toFixed(0);
        b[i] = a[j];
    }
    return b.join("");
}

function _generate_otp(length){
    var a = "1234567890".split("");
    var b = [];  
    for (var i = 0; i<length; i++) {
        var j = (Math.random() * (a.length-1)).toFixed(0);
        b[i] = a[j];
    }
    return b.join("");
}

function _getDifference(from_date, to_date){
    var date1 = from_date ? new Date(from_date) : new Date();
    var date2 = to_date ? new Date(to_date) : new Date();
    var diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24)) + 1; 
    return diffDays;
}

function _generateYearMonth(units, interval) {
    var ret = new Date(); //don't change original date
    /*console.log('newDate');
    console.log(ret);*/
    var newDate = new Date();
    var checkRollover = function() { if(ret.getDate() != newDate.getDate())ret.setDate(0);};
    if(units == 'm'){
        ret.setMonth(ret.getMonth() + parseInt(interval));
        checkRollover();
    }else {
        ret.setFullYear(ret.getFullYear() + parseInt(interval));
        checkRollover();
    }

    function addZero(i) {
        if (i < 10) {
            i = "0" + i;
        }
        return i;
    }
    var dd = addZero(ret.getDate().toString());
    var monthSingleDigit = ret.getMonth() + 1;
    var mm = addZero(monthSingleDigit);
    var yy = ret.getFullYear().toString();
    var hh = addZero(ret.getHours().toString());
    var ii = addZero(ret.getMinutes().toString());
    var ss = addZero(ret.getSeconds().toString());
    var timestamp = yy +'-'+ mm +'-'+ dd +' '+ hh +':'+ ii +':'+ ss;
    /*console.log('new date time ===>   ' + timestamp);*/
    return yy;
}

function _getDayMonthYear(date, type, format) {
    var nDate = date != undefined && date ? new Date(date) : new Date();
    var days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
    var months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    var day = nDate.getDay();
    var month = nDate.getMonth();
    var yy = nDate.getFullYear().toString();
    var result;
    if(format == '1' && format != undefined){
        if(type == 'd'){
            result = day;
        }else if(type == 'm'){
            result = month + 1;
        }else {
            result = yy;
        }
    }else if(format == '2' && format != undefined){
        if(type == 'd'){
            result = days[day];
        }else if(type == 'm'){
            result = months[month];
        }else {
            result = yy;
        }
    }else {
        if(type == 'd'){
            result = day;
        }else if(type == 'm'){
            result = month + 1;
        }else {
            result = yy;
        }
    }
    //console.log('result ===>   ' + result);
    return result;
}

function _getDayMonthName(data, type) {
    var days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday'];
    var months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    var result;
    if(type == 'd'){
        result = days[data];
    }else {
        result = months[(data - 1)];
    }
    //console.log('result ===>   ' + result);
    return result;
}

function _getCurrentDateMonthYear(date, type) {
    var nDate = date != undefined && date ? new Date(date) : new Date();
    var day = nDate.getDate();
    var month = nDate.getMonth();
    var yy = nDate.getFullYear().toString();
    var result;
    if(type == 'd'){
        result = day;
    }else if(type == 'm'){
        result = month + 1;
    }else {
        result = yy;
    }
    //console.log('result ===>   ' + result);
    return result;
}

function generateSecretKey(data){
    var key = '';
    return key;
}

function encode(data){
    return encodeURIComponent(escape(window.atob(data)));
}

function decode(data = ''){
    return decodeURIComponent(escape(window.atob(data)));
}

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
}

module.exports = { 
    generate_token, 
    _generateTimeStamp, 
    _generateTimes, 
    _generateDate,
    _generateDateFormat,
    _generate_otp, 
    generate_img_name,
    _getDifference,
    _generateYearMonth,
    _getDayMonthYear,
    _getDayMonthName,
    _getCurrentDateMonthYear,
    generateSecretKey,
    encode,
    decode,
    uuidv4
};
