import { STORAGE_KEY } from './state'

const localStoragePlugin = store => {
  store.subscribe((mutation, state) => {
    const syncedData = {
        user: state.user,
        exam: state.exam,
    };

    //localStorage.setItem(STORAGE_KEY, JSON.stringify(syncedData))
    //localStorage.setItem(STORAGE_KEY, JSON.stringify(syncedData))
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(syncedData))
    if (mutation.type === 'CLEAR_ALL_DATA') {
        // remove stored states
        window.localStorage.removeItem(STORAGE_KEY);
        // remove stored states
        window.sessionStorage.removeItem(STORAGE_KEY);
        // remove all chrome data related to this app
        window.localStorage.clear();
        // remove each cached data from window
        window.localStorage.clear();
        // remove each cached data from window
        window.sessionStorage.clear();
    }

    if (mutation.type === 'CLEAR_USER') {
        window.localStorage.removeItem('user')
    }

    if (mutation.type === 'CLEAR_EXAM') {
        window.localStorage.removeItem('exam')
    }
  })
}

// TODO: setup env
// export default process.env.NODE_ENV !== 'production' ? [localStoragePlugin] : [localStoragePlugin]
export default [localStoragePlugin]
