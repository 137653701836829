import Vue from 'vue';
import Vuex from 'vuex';
import { state } from './state';
import * as getters from './getters';
import * as actions from './actions';
import * as mutations from './mutations';
import plugins from './plugins';
Vue.use(Vuex);


const store = new Vuex.Store({
    //strict: true,  /* process.env.NODE_ENV !== 'production', */
    state,
    getters,
    actions,
    mutations,
    plugins
})

export default store
