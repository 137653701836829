// Set the key we'll use in local storage.
// Go to Chrome dev tools, application tab, click "Local Storage" and "http://localhost:8080"
// and you'll see this key set below (if logged in):
export const STORAGE_KEY = 'rectsk_test'

let syncedData = {
    user: {
        _id: null,
        userId: null,
        email: null,
        password: null,
        mobile: null,
        name: null,
        secret: null,
        status: null,
        isApproved: null,
        father_name: null,
        education: null,
        district: null,
        village: null,
        gender: null,
        image: null,
        createdAt: null,
        updatedAt: null,
    },
    exam: null,
}
// Sync with local storage.
if (window.localStorage.getItem(STORAGE_KEY)) {
    syncedData = JSON.parse(window.localStorage.getItem(STORAGE_KEY))
}
// Merge data and export it.
export const state = Object.assign(syncedData)
