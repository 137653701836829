<template>
	<div id="app">
		<app-main></app-main>
	</div>
</template>

<script>
	const AppMain = () => import('./container/AppMain')
	export default {
		name: 'App',
		components: {
			AppMain
		},
		data() {
			return {

			}
		}
	}
</script>
<style src="cxlt-vue2-toastr/dist/css/cxlt-vue2-toastr.css"></style>
<style>
	.sub-bnr {
		min-height: unset;
		padding: 0;
	}
	.padding-bottom-70, .padding-top-70{
		padding: 0 !important;
	}
	#content {
		padding: 20px 0;
	}
	section, .sub-bnr h1{
		color: #000;
	}
	.toast:not(.show), .toast{
		display: block !important;
		opacity: 1;
		visibility: visible;
	}
	.header .navbar li{
		margin: 10px 0 !important;
		display: inline-block !important;
	}
	.header .navbar li a {
		color: #000;
	}
	header .navbar li a.active, header .navbar li a:hover{
		background: #1193d4;
    	color: #fff;
	}
	html, body * {
		font-size: inherit;
		line-height: 1.5;
	}
</style>
