export const UPDATE_USER = (state, user) => {
  state.user = user
}

export const UPDATE_EXAM = (state, exam) => {
  state.exam = exam
}

/**
 * Clear each property, one by one, so reactivity still works.
 *
 * (ie. clear out state.auth.isLoggedIn so Navbar component automatically reacts to logged out state,
 * and the Navbar menu adjusts accordingly)
 *
 * TODO: use a common import of default state to reset these values with.
 */
export const CLEAR_ALL_DATA = (state) => {
    // User
    state.user._id = null;
    state.user.userId = null;
    state.user.email = null;
    state.user.password = null;
    state.user.mobile = null;
    state.user.name = null;
    state.user.secret = null;
    state.user.status = null;
    state.user.isApproved = null;
    state.user.father_name = null;
    state.user.education = null;
    state.user.district = null;
    state.user.village = null;
    state.user.gender = null;
    state.user.image = null;
    state.user.createdAt= null;
    state.user.updatedAt= null;

    // exam
    state.exam = null;
}

export const CLEAR_USER = (state) => {
    // user
    state.user._id = null;
    state.user.userId = null;
    state.user.email = null;
    state.user.password = null;
    state.user.mobile = null;
    state.user.name = null;
    state.user.gender = null;
    state.user.image = null;
    state.user.secret = null;
    state.user.status = null;
    state.user.isApproved = null;
    state.user.father_name = null;
    state.user.education = null;
    state.user.district = null;
    state.user.village = null;
    state.user.gender = null;
    state.user.image = null;
    state.user.createdAt= null;
    state.user.updatedAt= null;
}

export const CLEAR_EXAM = (state) => {
    // exam
    state.exam = null;
}